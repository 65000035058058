<!-- 弹框 -->
<template>
  <div>
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="70%"
    >
      <div>
        <div class="top-info">
          <TextInput
            :config="{
              label: '规则名称',
              type: 'input',
              layout: 'space-between',
            }"
            labelPosition="left"
            v-model="dataForm.ruleName"
          ></TextInput>
          <!-- <TextInput
            :config="{
              label: '绑定项目',
              type: 'slot',
            }"
            labelPosition="left"
            v-model="dataForm.projectName"
          >
            <div @click="bindProjectOpen = true" class="el-input el-input--small">
              <span style="text-align: left" class="el-input__inner">{{
                dataForm.projectName
              }}</span>
            </div>
          </TextInput> -->
          <TextInput
            @returnVal="returnVal($event, dataForm)"
            :config="{
              label: '方案名称',
              type: 'select',
              modelTextKey: 'programmeName',
              option: dictList.programmeList,
              layout: 'space-between',
            }"
            :optionConfig="{
              value: 'id',
              label: 'insureProgrammeName',
              layout: 'space-between',
            }"
            labelPosition="left"
            v-model="dataForm.programmeId"
          ></TextInput>
          <TextInput
            :config="{
              label: '税后手续费率',
              type: 'input',
              disabled: true,
              layout: 'space-between',
            }"
            labelPosition="left"
            v-model="dataForm.afterTaxRate"
            ><span slot="inputAppend">%</span></TextInput
          >
          <TextInput
            :config="{
              label: '规则状态',
              type: 'radio',
              option: dictList.isDisabled,
              layout: 'space-between',
            }"
            labelPosition="left"
            v-model="dataForm.status"
          ></TextInput>
        </div>

        <!-- 初次分配表格 -->
        <div>
          <el-table
            :data="distributionTables.table1"
            :span-method="objectSpanMethod"
            :cell-class-name="cellClassNme"
            :row-class-name="setRowClassName"
            :header-cell-style="{
              background: '#FFF2F7FD',
            }"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="ID">
              <template slot-scope="{ $index }">{{ $index / 2 + 1 }}</template>
            </el-table-column>
            <el-table-column prop="deptName" label="部门名称">
              <template slot-scope="{ row, $index }">
                <div
                  v-if="$index % 2 === 0"
                  style="display: flex; justify-content: space-between"
                >
                  <div>{{ row.deptName }}</div>
                  <div style="cursor: pointer">
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
                <div class="inner-table" v-else>
                  <el-table
                    :span-method="objectSpanMethod2"
                    :data="row.innerTable || []"
                    :row-class-name="
                      (srow) => {
                        return setRowClassName2(srow, $index);
                      }
                    "
                    border
                    style="width: 100%"
                  >
                    <template slot="empty">
                      <div
                        @click="teamLederAdd(row, 1)"
                        :class="[
                          ($index + 1) % 4 === 0 ? 'row-highlight' : '',
                          'team-leder-add',
                        ]"
                        style="width: 100%"
                      >
                        添加团队长
                      </div>
                    </template>
                    <el-table-column prop="userId">
                      <template slot-scope="scope">
                        <TextInput
                          :config="{
                            type: 'select',
                            option: row.teamLeaderNameList,
                            width: '100%',
                            placeholder: '请选择团队长',
                          }"
                          v-model="scope.row.userName"
                        >
                          <!-- <EmployeeSelect
                              v-model="scope.row.userId"
                              placeholder="请选择"
                              @changeChecked="changeUser($event, scope.row)"
                            /> -->
                        </TextInput>
                      </template>
                    </el-table-column>
                    <el-table-column prop="performanceRate"
                      ><template slot-scope="scope">
                        <TextInput
                          @returnVal="returnVal($event, row, $index - 1)"
                          :config="{
                            type: 'input',
                            width: '100%',
                            modelTextKey: 'performanceRate1',
                            placeholder: '团队长绩效费率',
                            isNum: true,
                          }"
                          v-model="scope.row.performanceRate"
                          ><span slot="inputAppend">%</span></TextInput
                        >
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <!-- <template slot-scope="{ $index }">
                        <div style="display: flex">
                          <i
                            @click="teamLederDelet(row, $index)"
                            class="el-icon-delete-solid"
                          ></i>
                          <div
                            class="team-leder-add"
                            @click="teamLederAdd(row, 1)"
                            v-if="!$index"
                          >
                            <i class="el-icon-plus"></i>
                            添加团队长
                          </div>
                        </div>
                      </template> -->
                      <template slot-scope="scope">
                        <div
                          class="flex-center-between"
                          style="padding: 0 12px"
                        >
                          <!-- <i
                            @click="teamLederDelet(row, $index)"
                            class="el-icon-delete-solid"
                          ></i> -->
                          <img
                            @click="
                              teamLederDelet(row, scope.$index, 1, $index)
                            "
                            style="width: 24px; height: 24px; cursor: pointer"
                            src="@/assets/images/kpi/delet.png"
                          />
                          <div
                            class="team-leder-add"
                            @click="teamLederAdd(row, 1)"
                            v-if="!scope.$index"
                          >
                            <i class="el-icon-plus"></i>
                            添加团队长
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 辅助对齐用 -->
                    <el-table-column> </el-table-column>
                    <el-table-column> </el-table-column>
                    <el-table-column> </el-table-column>
                    <!-- 辅助对齐用 -->
                  </el-table>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="referralFee" label="业务推荐费率">
              <template slot-scope="{ row, $index }">
                <TextInput
                  @returnVal="returnVal($event, row, $index)"
                  :config="{
                    type: 'input',
                    modelTextKey: 'referralFee',
                    width: '100%',
                    isNum: true,
                  }"
                  v-model="row.referralFee"
                  ><span slot="inputAppend">%</span></TextInput
                >
              </template>
            </el-table-column>
            <el-table-column prop="individualFee" label="个人绩效费率">
              <template slot-scope="{ row, $index }"
                ><TextInput
                  @returnVal="returnVal($event, row, $index)"
                  :config="{
                    type: 'input',
                    modelTextKey: 'individualFee',
                    width: '100%',
                    isNum: true,
                  }"
                  v-model="row.individualFee"
                  ><span slot="inputAppend">%</span></TextInput
                ></template
              >
            </el-table-column>
            <el-table-column prop="floatFlag" label="是否浮动计算">
              <template slot-scope="{ row }">
                <TextInput
                  @returnVal="returnVal"
                  :config="{
                    type: 'select',
                    option: dictList.isFloat,
                    modelTextKey: 'isFloat',
                    width: '100%',
                  }"
                  v-model="row.floatFlag"
                ></TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="assessmentFee" label="公估服务费率">
              <template slot-scope="{ row, $index }"
                ><TextInput
                  @returnVal="returnVal($event, row, $index)"
                  :config="{
                    type: 'input',
                    modelTextKey: 'assessmentFee',
                    width: '100%',
                    isNum: true,
                  }"
                  v-model="row.assessmentFee"
                  ><span slot="inputAppend">%</span></TextInput
                ></template
              >
            </el-table-column>
            <el-table-column prop="companyReserveFee" label="公司保留率(%)">
              <div slot-scope="{ row, $index }">
                <span v-if="row.companyReserveFee < 0" style="color: #f64a2d"
                  >{{ row.companyReserveFee }},保留率小于0！</span
                >
                <span v-else>{{ row.companyReserveFee }}</span>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <!-- 二次分配表格 -->
        <div>
          <TextInput
            :config="{
              label: '二次分配',
              type: 'radio',
              option: dictList.isOrNot,
            }"
            labelPosition="left"
            v-model="dataForm.secondaryAllocation"
          ></TextInput>
          <div v-if="dataForm.secondaryAllocation">
            <el-table
              :data="distributionTables.table2"
              :row-class-name="setRowClassName3"
              :header-cell-style="{
                background: '#FFF2F7FD',
              }"
              border
              style="width: 100%"
            >
              <el-table-column type="index" label="ID"></el-table-column>
              <el-table-column
                label="部门名称"
                prop="deptName"
              ></el-table-column>
              <el-table-column label="部门保留" prop="sdeptReserve"
                ><template slot-scope="{ row, $index }"
                  ><TextInput
                    @returnVal="returnVal($event, row, $index)"
                    :config="{
                      type: 'input',
                      width: '100%',
                      modelTextKey: 'sdeptReserve',
                      isNum: true,
                    }"
                    v-model="row.sdeptReserve"
                    ><span slot="inputAppend">%</span></TextInput
                  ></template
                ></el-table-column
              >
              <el-table-column label="个人分配比例" prop="sindividualReserve"
                ><template slot-scope="{ row, $index }"
                  ><TextInput
                    @returnVal="returnVal($event, row, $index)"
                    :config="{
                      type: 'input',
                      width: '100%',
                      modelTextKey: 'sindividualReserve',
                      isNum: true,
                    }"
                    v-model="row.sindividualReserve"
                    ><span slot="inputAppend">%</span></TextInput
                  ></template
                ></el-table-column
              >
              <el-table-column label="公司保留%" prop="scompanyReserve">
                <div slot-scope="{ row, $index }">
                  <span v-if="row.scompanyReserve < 0" style="color: #f64a2d"
                    >{{ row.scompanyReserve }},保留率小于0！</span
                  >
                  <span v-else>{{ row.scompanyReserve }}</span>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 三次分配表格 -->
        <div v-if="dataForm.secondaryAllocation">
          <TextInput
            :config="{
              label: '三次分配',
              type: 'radio',
              option: dictList.isOrNot,
            }"
            labelPosition="left"
            v-model="dataForm.cubicDistribution"
          ></TextInput>
          <div v-if="dataForm.cubicDistribution">
            <el-table
              :data="distributionTables.table3"
              :span-method="objectSpanMethod3"
              :cell-class-name="cellClassNme"
              :row-class-name="setRowClassName"
              :header-cell-style="{
                background: '#FFF2F7FD',
              }"
              border
              style="width: 100%"
            >
              <el-table-column label="ID" type="index"
                ><template slot-scope="{ $index }">{{
                  $index / 2 + 1
                }}</template></el-table-column
              >
              <el-table-column label="部门名称" prop="deptName"
                ><template slot-scope="{ row, $index }">
                  <div
                    v-if="$index % 2 === 0"
                    style="display: flex; justify-content: space-between"
                  >
                    <div>{{ row.deptName }}</div>
                    <div style="cursor: pointer">
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                  <div class="inner-table" v-else>
                    <el-table
                      :data="row.innerTable || []"
                      border
                      style="width: 100%"
                      :row-class-name="
                        (srow) => {
                          return setRowClassName2(srow, $index);
                        }
                      "
                    >
                      <template slot="empty">
                        <div
                          @click="teamLederAdd(row, 2)"
                          :class="[
                            ($index + 1) % 4 === 0 ? 'row-highlight' : '',
                            'team-leder-add',
                          ]"
                        >
                          添加团队长
                        </div>
                      </template>
                      <el-table-column prop="userId">
                        <template slot-scope="scope">
                          <!-- <TextInput
                              :config="{
                                type: 'select',
                              }"
                              v-model="scope.row.userId"
                            >
                              <EmployeeSelect
                                v-model="scope.row.userId"
                                placeholder="请选择"
                                @changeChecked="changeUser($event, scope.row)"
                              />
                            </TextInput> -->
                          <TextInput
                            :config="{
                              type: 'select',
                              option: row.teamLeaderNameList,
                              width: '100%',
                              placeholder: '请选择团队长',
                            }"
                            v-model="scope.row.userName"
                          >
                          </TextInput>
                        </template>
                      </el-table-column>
                      <el-table-column prop="performanceRate"
                        ><template slot-scope="scope">
                          <TextInput
                            @returnVal="returnVal($event, row, $index - 1)"
                            :config="{
                              type: 'input',
                              width: '100%',
                              modelTextKey: 'performanceRate3',
                              placeholder: '团队长绩效费率',
                              isNum: true,
                            }"
                            v-model="scope.row.performanceRate"
                            ><span slot="inputAppend">%</span></TextInput
                          >
                        </template>
                      </el-table-column>
                      <el-table-column width="200">
                        <!-- <template slot-scope="{ $index }">
                          <div style="display: flex">
                            <i
                              @click="teamLederDelet(row, $index)"
                              class="el-icon-delete-solid"
                            ></i>
                            <div
                              class="team-leder-add"
                              @click="teamLederAdd(row, 2)"
                              v-if="!$index"
                            >
                              <i class="el-icon-plus"></i>
                              添加团队长
                            </div>
                          </div>
                        </template> -->
                        <template slot-scope="scope">
                          <div
                            class="flex-center-between"
                            style="padding: 0 12px"
                          >
                            <!-- <i
                            @click="teamLederDelet(row, $index)"
                            class="el-icon-delete-solid"
                          ></i> -->
                            <img
                              @click="
                                teamLederDelet(row, scope.$index, 2, $index)
                              "
                              style="width: 24px; height: 24px; cursor: pointer"
                              src="@/assets/images/kpi/delet.png"
                            />
                            <div
                              class="team-leder-add"
                              @click="teamLederAdd(row, 2)"
                              v-if="!scope.$index"
                            >
                              <i class="el-icon-plus"></i>
                              添加团队长
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template></el-table-column
              >
              <el-table-column label="部门保留(%)" prop="cdeptReserve">
                <template slot-scope="{ row, $index }">
                  <span v-if="row.cdeptReserve < 0" style="color: #f64a2d"
                    >{{ row.cdeptReserve }},保留率小于0！</span
                  >
                  <span v-else>{{ row.cdeptReserve }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <projectDialog
      :dialogVisible.sync="bindProjectOpen"
      @submit="bindProject"
      :multiple="false"
    ></projectDialog> -->
  </div>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
//   import projectDialog from "@/views/SystemManagement/components/customer/projectDialog.vue";
//   import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { getKpiProjectList, addKpiRule } from "@/api/kpi.js";
export default {
  name: "CommonRuleAdditionDialog",
  components: {
    TextInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      n && this.init();
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.clearForm();
        this.$emit("update:show", false);
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      bindProjectOpen: false,
      dictList: {
        //是否禁用字典
        isDisabled: [
          { label: "启用", value: true },
          { label: "禁用", value: false },
        ],
        //是否浮动计算
        isFloat: [
          { label: "不浮动", value: 0 },
          { label: "个人绩效浮动", value: 1 },
          { label: "业务推荐费浮动", value: 2 },
        ],
        //是或否
        isOrNot: [
          { label: "是", value: true },
          { label: "否", value: false },
        ],
        //方案列表
        programmeList: [],
        //部门列表
        departmentList: [],
        //团队长列表
        teamLederList: [],
      },
      dataForm: {
        dataType: 1,
        afterTaxRate: "",
        programmeId: "",
        programmeName: "",
        projectId: "",
        projectName: "",
        ruleName: "",
        status: true,
        secondaryAllocation: false,
        cubicDistribution: false,
        performanceRuleSchemeList: [],
        delLeaderIds: [],
      },
      //多次分配表格
      distributionTables: {
        table1: [],
        table2: [],
        table3: [],
      },
    };
  },
  created() {
    //this.handleTableData(this.dataForm.performanceRuleSchemeList);
  },
  methods: {
    init() {
      getKpiProjectList().then((res) => {
        this.dictList.programmeList = res.data || [];
        if (this.detailData) {
          this.dataForm = { ...this.detailData };
          this.handleTableData(this.dataForm.performanceRuleSchemeList || []);
        }
      });
    },
    handleTableData(list = [], init = true) {
      // type = 1 初次 2 三次
      let tb1 = [];
      let tb2 = [];
      let tb3 = [];
      this.dataForm.performanceRuleSchemeList = list;
      list.forEach((item) => {
        let innerTable1 = [];
        let innerTable3 = [];
        if (init) {
          innerTable1 =
            item?.schemeSectionList.filter((innerItem) => {
              return innerItem.dataType == 1;
            }) || [];
          tb1.push(item, {
            innerTable: innerTable1,
            deptId: item.deptId,
            teamLeaderNameList:
              item.teamLeaderNameList || this.getTeamLeaderList(item.deptId),
          });
        }
        innerTable3 =
          item?.schemeSectionList.filter((innerItem) => {
            return innerItem.dataType == 2;
          }) || [];
        item.floatFlag &&
          tb3.push(item, {
            innerTable: innerTable3,
            deptId: item.deptId,
            teamLeaderNameList:
              item.teamLeaderNameList || this.getTeamLeaderList(item.deptId),
          });
      });

      tb2 = this.dataForm.performanceRuleSchemeList.filter((item) => {
        return item.floatFlag;
      });
      init && (this.distributionTables.table1 = tb1);
      this.distributionTables.table2 = tb2;
      this.distributionTables.table3 = tb3;
    },
    //获取方案对应的团队长字典
    getTeamLeaderList(deptId = "") {
      let programme = this.dictList.programmeList.find((p) => {
        return p.id == this.dataForm.programmeId;
      });
      let dept = [];
      if (programme) {
        dept = programme.deptList.find((d) => {
          return d.id == deptId;
        });
      }
      if (dept) {
        return dept?.teamLeaderNameList?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      }
      return [];
    },
    //添加二次或者三次分配数据
    changeDistributionData(row) {},
    //更改方案切换部门
    changeProgrammeFromDept(dpList = []) {
      let tb = [];
      for (const key in this.distributionTables) {
        this.distributionTables[key] = [];
      }
      dpList.forEach((item) => {
        tb.push({
          assessmentFee: "",
          cdeptReserve: "",
          companyReserveFee: "",
          cubicDistribution: false,
          deptId: item.id,
          deptName: item.name,
          floatFlag: '',
          individualFee: "",
          referralFee: "",
          scompanyReserve: "",
          sdeptReserve: "",
          secondaryAllocation: false,
          sindividualReserve: "",
          schemeSectionList: [],
          teamLeaderNameList: item.teamLeaderNameList.map((kv) => {
            return {
              label: kv,
              value: kv,
            };
          }),
        });
      });
      // this.dataForm.performanceRuleSchemeList = tb;
      this.handleTableData(tb, true);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        if (rowIndex % 2 !== 0) {
          return {
            rowspan: 1,
            colspan: 7,
          };
        }
      }
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          colspan: 2,
          rowspan: 1,
        };
      } else if (columnIndex === 1) {
        return {
          colspan: 3,
          rowspan: 1,
        };
      }
    },
    objectSpanMethod3({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        if (rowIndex % 2 !== 0) {
          return {
            rowspan: 1,
            colspan: 4,
          };
        }
      }
    },
    cellClassNme({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 !== 0) {
        return "row-span";
      }
    },
    setRowClassName({ row, rowIndex }) {
      if (rowIndex % 4 !== 0) {
        return "row-highlight";
      }
    },
    setRowClassName2(srow, findex) {
      //   let { row, rowIndex } = srow;
      if ((findex + 1) % 4 === 0) {
        return "row-highlight";
      }
    },
    setRowClassName3({ row, column, rowIndex, columnIndex }) {
      //   let { row, rowIndex } = srow;
      if (rowIndex % 2 !== 0) {
        return "row-highlight";
      }
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
      for (const key in this.distributionTables) {
        this.distributionTables[key] = [];
      }
    },
    bindProject(e, p) {
      this.dataForm.projectId = e.projectId[0];
      this.dataForm.projectName = p[0].projectName;
      this.bindProjectOpen = false;
    },
    returnVal(e, obj, index) {
      if (e.type == "programmeName") {
        this.dataForm.afterTaxRate = e.data.afterTaxRate || 0;
        obj[e.type] = e.data.insureProgrammeName;
        this.changeProgrammeFromDept(e.data.deptList || []);
      } else if (e.type == "isFloat") {
        // this.distributionTables.table2 =
        //   this.dataForm.performanceRuleSchemeList.filter((item) => {
        //     return item.floatFlag;
        //   });
        this.handleTableData(this.dataForm.performanceRuleSchemeList, false);
      } else if (
        e.type == "individualFee" ||
        e.type == "referralFee" ||
        e.type == "assessmentFee" ||
        e.type == "performanceRate1"
      ) {
        this.distributionTables.table1[index].companyReserveFee =
          this.computeCompanyRate(index);
        // if (e.type == "individualFee") {
        //   this.computeSecondaryAllocationByFirst(index);
        // }
      } else if (e.type == "sdeptReserve" || e.type == "sindividualReserve") {
        this.computeSecondaryAllocation(obj);
        // if (e.type == "sdeptReserve") {
        //   this.distributionTables.table3[index * 2].cdeptReserve =
        //     this.computeDepartmentReteBySecond(index * 2);
        // }
      } else if (e.type == "performanceRate3") {
        this.distributionTables.table3[index].cdeptReserve =
          this.computeDepartmentRete(index);
      }
    },
    teamLederAdd(row, type) {
      row.innerTable.push({
        dataType: type,
        performanceRate: "",
        userId: "",
        userName: "",
      });
    },
    teamLederDelet(row, index, type, $index) {
      if (row.innerTable[index].id) {
        this.dataForm.delLeaderIds.push(row.innerTable[index].id);
      }
      row.innerTable.splice(index, 1);
      if (type == 1) {
        this.distributionTables.table1[$index - 1].companyReserveFee =
          this.computeCompanyRate($index - 1);
      } else if (type == 2) {
        this.distributionTables.table3[$index - 1].cdeptReserve =
          this.computeDepartmentRete($index - 1);
      }
    },
    //计算公司保留率
    computeCompanyRate(index) {
      //=税后手续费率-业务推荐费率-个人绩效费率-部门团队长费率之和-公估服务费率
      let tb = this.distributionTables.table1;
      let fee =
        (this.dataForm.afterTaxRate || 0) -
        (tb[index].referralFee || 0) -
        (tb[index].individualFee || 0) -
        (tb[index].assessmentFee || 0);
      let lederFee = 0;
      let res = "";
      let itb = this.distributionTables.table1[index + 1]?.innerTable;
      if (itb?.length) {
        itb.forEach((it) => {
          lederFee += Number(it.performanceRate) || 0;
        });
      }
      res = fee - lederFee;
      //>= 0 ? res : "保留率小于0";
      return res.toFixed(2);
    },
    //计算部门保留率
    computeDepartmentRete(index) {
      //=第二次的部门保留-部门团队长费率之和
      // let tb = this.distributionTables.table3;
      // let fee = tb[index].sdeptReserve || 0;
      //=100%-部门团队长费率之和
      let fee = 100;
      let lederFee = 0;
      let res = "";
      let itb = this.distributionTables.table3[index + 1]?.innerTable;
      if (itb?.length) {
        itb.forEach((it) => {
          lederFee += Number(it.performanceRate) || 0;
        });
      }
      res = fee - lederFee;
      //>= 0 ? res : "保留率小于0";
      return res.toFixed(2);
    },
    //二次分配计算
    computeSecondaryAllocation(row) {
      //个人绩效费率 - 部门保留% - 个人保留%
      // row.scompanyReserve =
      //   (Number(row?.individualFee || 0) || 0) -
      //   (Number(row?.sindividualReserve || 0) || 0) -
      //   (Number(row?.sdeptReserve || 0) || 0);
      //100% - 部门保留% - 个人保留%
      let res =
        100 -
        (Number(row?.sindividualReserve || 0) || 0) -
        (Number(row?.sdeptReserve || 0) || 0);
      row.scompanyReserve = res.toFixed(2);
    },
    changeUser(e, item) {
      item.userId = e.id;
      item.userName = e.title;
    },
    //校验保留率是否大于等于0
    checkReserveRate() {
      let flag = false;
      this.dataForm.performanceRuleSchemeList.some((ps) => {
        if (
          Number(ps.companyReserveFee || 0) < 0 ||
          Number(ps.scompanyReserve || 0) < 0 ||
          Number(ps.cdeptReserve || 0) < 0
        ) {
          flag = true;
          return true;
        }
      });
      return flag;
    },
    submitForm() {
      //console.log(this.dataForm, this.distributionTables);
      this.dataForm.performanceRuleSchemeList.forEach((scitem) => {
        scitem.schemeSectionList = [];
        let d1 = this.distributionTables.table1.filter((d) => {
          return d.deptId == scitem.deptId && d?.innerTable;
        });
        let d3 = this.distributionTables.table3.filter((d) => {
          return d.deptId == scitem.deptId && d?.innerTable;
        });
        //console.log(d1, d3);
        scitem.schemeSectionList = [
          ...[...(d1[0]?.innerTable || [])],
          ...[...(d3[0]?.innerTable || [])],
        ];
      });
      //console.log(this.dataForm);
      if (this.checkReserveRate()) {
        this.$message({
          message: "存在保留率小于0!",
          type: "warning",
        });
      } else {
        addKpiRule({ ...this.dataForm, dataType: 1 }).then(() => {
          this.$emit("submit");
          //this.clearForm();
          this.dialogVisible = false;
          this.$message({
            message: "添加或修改成功",
            type: "success",
          });
        });
      }
    },
    //一次分配修改个人绩效费率，更改二次分配对应的公司保留
    computeSecondaryAllocationByFirst(index) {
      this.dataForm.secondaryAllocation &&
        this.computeSecondaryAllocation(
          this.distributionTables.table2[index / 2]
        );
    },
    //二次部门保留率修改，更改三次的部门保留率
    computeDepartmentReteBySecond(index) {
      if (this.dataForm.cubicDistribution) {
        return this.computeDepartmentRete(index);
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .inner-table {
  .el-table__header-wrapper {
    display: none !important;
  }
}
::v-deep .el-table__empty-text {
  width: 100%;
}
::v-deep .el-dialog__body {
  padding: 0 32px;
}
::v-deep .row-span {
  padding: unset;
  //border: 0 !important;

  > div {
    padding: unset !important;
  }
}
::v-deep .row-bg {
  background-color: #fffafafa;
}
::v-deep .row-highlight {
  background-color: #fffafafa;
}
::v-deep .header-row {
  background-color: #4278c9 !important;
}
.team-leder-add {
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  cursor: pointer;
}
// .el-icon-delete-solid {
//   color: #f64a2d;
//   font-size: 20px;
// }
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-info {
  width: fit-content;
}
</style>
