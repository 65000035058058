<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-15 09:19:15
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-06-03 16:36:51
 * @Description: 
-->
<template>
  <div>
    <SimpleTable :tableProps="tableProps" :queryFun="queryFun" ref="table">
      <el-button
        slot="left-r"
        class="add-btn"
        icon="el-icon-circle-plus-outline"
        @click="addRule"
        >添加</el-button
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(data.row, true)"
          >编辑</el-button
        >
        <el-button
          type="text"
          style="color: #f64a2d"
          @click="deletRuleByid(data.row)"
          >删除</el-button
        >
      </div>
      <div
        style="display: flex; align-items: center"
        slot="status"
        slot-scope="{ data }"
      >
        <div
          class="status"
          :style="{
            backgroundColor: findDict(data.row.status, 'statusDict').color,
          }"
        ></div>
        <span
          :style="{
            color: findDict(data.row.status, 'statusDict').color,
          }"
          >{{ findDict(data.row.status, "statusDict").dictValue }}</span
        >
      </div>
    </SimpleTable>
    <CommonRuleAdditionDialog
      :show.sync="showAdd"
      :detailData="detailData"
      @submit="$refs.table.getData()"
    ></CommonRuleAdditionDialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable/index.vue";
import CommonRuleAdditionDialog from "./components/CommonRuleAdditionDialog.vue";
import {
  getKpiRuleList,
  deleteKpiRule,
  getKpiRuleDetail,
  getKpiProjectList,
} from "@/api/kpi.js";
import { statusDict } from "@/views/routerPerformanceManagement/const.js";
export default {
  components: { SimpleTable, CommonRuleAdditionDialog },
  mounted() {
    this.setSearchConfigs();
  },
  data() {
    return {
      showAdd: false,
      detailData: null,
      tableProps: {
        currentPageKey: "current",
        currentSizeKey: "size",
        isFuzzyQuery: false,
        isSelection: false,
        height: 500,
        border: true,
        searchConfigs: [
          //要同时修改方法里面的配置
          {
            modelKey: "ruleName",
            label: "规则名称",
            type: "input",
          },
          {
            modelKey: "status",
            label: "状态",
            type: "select",
            option: statusDict,
          },
          {
            modelKey: "createTime",
            label: "创建时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
          },
          {
            modelKey: "updateTime",
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
          },
        //   {
        //     modelKey: "projectName",
        //     label: "项目名称",
        //     type: "input",
        //   },
          {
            modelKey: "programmeId",
            label: "方案名称",
            type: "select",
            option: [],
          },
        ],
        columnProps: [
          { type: "index", isShow: true, label: "ID" },
          { prop: "ruleName", isShow: true, label: "规则名称" },
        //   { prop: "projectName", isShow: true, label: "项目名称" },
          { prop: "programmeName", isShow: true, label: "方案名称" },
          { prop: "visibleDept", isShow: true, label: "可见部门" },
          {
            prop: "status",
            isShow: true,
            label: "状态",
            slot: true,
            width: 100,
          },
          { prop: "createTime", isShow: true, label: "创建时间" },
          { prop: "updateTime", isShow: true, label: "更新时间" },
          {
            prop: "action",
            slot: true,
            isShow: true,
            label: "操作",
            width: 100,
          },
        ],
      },
      dictList: {
        statusDict: statusDict,
        //方案列表
        programmeList: [],
      },
    };
  },
  methods: {
    async queryFun(params) {
      let res = await getKpiRuleList({ ...params, dataType: 1 });
      return {
        localData: res.data.records,
        total: res.data.total || 0,
      };
    },
    addRule() {
      this.detailData = null;
      this.showAdd = true;
    },
    toDetail(row) {
      getKpiRuleDetail({ id: row.id }).then((res) => {
        this.detailData = res.data;
        this.showAdd = true;
      });
    },
    deletRuleByid(row) {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteKpiRule({ id: row.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    findDict(key = "", type = "") {
      return (
        this.dictList[type]?.find((item) => {
          return item.dictKey == key;
        }) || {}
      );
    },
    async setSearchConfigs() {
      let res = await getKpiProjectList();
      this.dictList.programmeList = res.data.map((item) => {
        return {
          dictKey: item.id,
          dictValue: item.insureProgrammeName,
        };
      });
      this.tableProps.searchConfigs = [
        {
          modelKey: "ruleName",
          label: "规则名称",
          type: "input",
        },
        {
          modelKey: "status",
          label: "状态",
          type: "select",
          option: statusDict,
        },
        {
          modelKey: "createTime",
          label: "创建时间",
          type: "daterange",
          daterangeKeys: ["createTimeStart", "createTimeEnd"],
        },
        {
          modelKey: "updateTime",
          label: "更新时间",
          type: "daterange",
          daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
        },
        // {
        //   modelKey: "projectName",
        //   label: "项目名称",
        //   type: "input",
        // },
        {
          modelKey: "programmeId",
          label: "方案名称",
          type: "select",
          option: this.dictList.programmeList,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 3px;
}
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
</style>
