var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":"添加","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('div',{staticClass:"top-info"},[_c('TextInput',{attrs:{"config":{
            label: '规则名称',
            type: 'input',
            layout: 'space-between',
          },"labelPosition":"left"},model:{value:(_vm.dataForm.ruleName),callback:function ($$v) {_vm.$set(_vm.dataForm, "ruleName", $$v)},expression:"dataForm.ruleName"}}),_c('TextInput',{attrs:{"config":{
            label: '方案名称',
            type: 'select',
            modelTextKey: 'programmeName',
            option: _vm.dictList.programmeList,
            layout: 'space-between',
          },"optionConfig":{
            value: 'id',
            label: 'insureProgrammeName',
            layout: 'space-between',
          },"labelPosition":"left"},on:{"returnVal":function($event){return _vm.returnVal($event, _vm.dataForm)}},model:{value:(_vm.dataForm.programmeId),callback:function ($$v) {_vm.$set(_vm.dataForm, "programmeId", $$v)},expression:"dataForm.programmeId"}}),_c('TextInput',{attrs:{"config":{
            label: '税后手续费率',
            type: 'input',
            disabled: true,
            layout: 'space-between',
          },"labelPosition":"left"},model:{value:(_vm.dataForm.afterTaxRate),callback:function ($$v) {_vm.$set(_vm.dataForm, "afterTaxRate", $$v)},expression:"dataForm.afterTaxRate"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])]),_c('TextInput',{attrs:{"config":{
            label: '规则状态',
            type: 'radio',
            option: _vm.dictList.isDisabled,
            layout: 'space-between',
          },"labelPosition":"left"},model:{value:(_vm.dataForm.status),callback:function ($$v) {_vm.$set(_vm.dataForm, "status", $$v)},expression:"dataForm.status"}})],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.distributionTables.table1,"span-method":_vm.objectSpanMethod,"cell-class-name":_vm.cellClassNme,"row-class-name":_vm.setRowClassName,"header-cell-style":{
            background: '#FFF2F7FD',
          },"border":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID"},scopedSlots:_vm._u([{key:"default",fn:function({ $index }){return [_vm._v(_vm._s($index / 2 + 1))]}}])}),_c('el-table-column',{attrs:{"prop":"deptName","label":"部门名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [($index % 2 === 0)?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v(_vm._s(row.deptName))]),_c('div',{staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"el-icon-arrow-down"})])]):_c('div',{staticClass:"inner-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"span-method":_vm.objectSpanMethod2,"data":row.innerTable || [],"row-class-name":(srow) => {
                      return _vm.setRowClassName2(srow, $index);
                    },"border":""}},[_c('template',{slot:"empty"},[_c('div',{class:[
                        ($index + 1) % 4 === 0 ? 'row-highlight' : '',
                        'team-leder-add',
                      ],staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.teamLederAdd(row, 1)}}},[_vm._v(" 添加团队长 ")])]),_c('el-table-column',{attrs:{"prop":"userId"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TextInput',{attrs:{"config":{
                          type: 'select',
                          option: row.teamLeaderNameList,
                          width: '100%',
                          placeholder: '请选择团队长',
                        }},model:{value:(scope.row.userName),callback:function ($$v) {_vm.$set(scope.row, "userName", $$v)},expression:"scope.row.userName"}})]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"performanceRate"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TextInput',{attrs:{"config":{
                          type: 'input',
                          width: '100%',
                          modelTextKey: 'performanceRate1',
                          placeholder: '团队长绩效费率',
                          isNum: true,
                        }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index - 1)}},model:{value:(scope.row.performanceRate),callback:function ($$v) {_vm.$set(scope.row, "performanceRate", $$v)},expression:"scope.row.performanceRate"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}],null,true)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex-center-between",staticStyle:{"padding":"0 12px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px","cursor":"pointer"},attrs:{"src":require("@/assets/images/kpi/delet.png")},on:{"click":function($event){return _vm.teamLederDelet(row, scope.$index, 1, $index)}}}),(!scope.$index)?_c('div',{staticClass:"team-leder-add",on:{"click":function($event){return _vm.teamLederAdd(row, 1)}}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 添加团队长 ")]):_vm._e()])]}}],null,true)}),_c('el-table-column'),_c('el-table-column'),_c('el-table-column')],2)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"referralFee","label":"业务推荐费率"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('TextInput',{attrs:{"config":{
                  type: 'input',
                  modelTextKey: 'referralFee',
                  width: '100%',
                  isNum: true,
                }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index)}},model:{value:(row.referralFee),callback:function ($$v) {_vm.$set(row, "referralFee", $$v)},expression:"row.referralFee"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}])}),_c('el-table-column',{attrs:{"prop":"individualFee","label":"个人绩效费率"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('TextInput',{attrs:{"config":{
                  type: 'input',
                  modelTextKey: 'individualFee',
                  width: '100%',
                  isNum: true,
                }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index)}},model:{value:(row.individualFee),callback:function ($$v) {_vm.$set(row, "individualFee", $$v)},expression:"row.individualFee"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}])}),_c('el-table-column',{attrs:{"prop":"floatFlag","label":"是否浮动计算"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"config":{
                  type: 'select',
                  option: _vm.dictList.isFloat,
                  modelTextKey: 'isFloat',
                  width: '100%',
                }},on:{"returnVal":_vm.returnVal},model:{value:(row.floatFlag),callback:function ($$v) {_vm.$set(row, "floatFlag", $$v)},expression:"row.floatFlag"}})]}}])}),_c('el-table-column',{attrs:{"prop":"assessmentFee","label":"公估服务费率"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('TextInput',{attrs:{"config":{
                  type: 'input',
                  modelTextKey: 'assessmentFee',
                  width: '100%',
                  isNum: true,
                }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index)}},model:{value:(row.assessmentFee),callback:function ($$v) {_vm.$set(row, "assessmentFee", $$v)},expression:"row.assessmentFee"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}])}),_c('el-table-column',{attrs:{"prop":"companyReserveFee","label":"公司保留率(%)"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return _c('div',{},[(row.companyReserveFee < 0)?_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v(_vm._s(row.companyReserveFee)+",保留率小于0！")]):_c('span',[_vm._v(_vm._s(row.companyReserveFee))])])}}])})],1)],1),_c('div',[_c('TextInput',{attrs:{"config":{
            label: '二次分配',
            type: 'radio',
            option: _vm.dictList.isOrNot,
          },"labelPosition":"left"},model:{value:(_vm.dataForm.secondaryAllocation),callback:function ($$v) {_vm.$set(_vm.dataForm, "secondaryAllocation", $$v)},expression:"dataForm.secondaryAllocation"}}),(_vm.dataForm.secondaryAllocation)?_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.distributionTables.table2,"row-class-name":_vm.setRowClassName3,"header-cell-style":{
              background: '#FFF2F7FD',
            },"border":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID"}}),_c('el-table-column',{attrs:{"label":"部门名称","prop":"deptName"}}),_c('el-table-column',{attrs:{"label":"部门保留","prop":"sdeptReserve"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('TextInput',{attrs:{"config":{
                    type: 'input',
                    width: '100%',
                    modelTextKey: 'sdeptReserve',
                    isNum: true,
                  }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index)}},model:{value:(row.sdeptReserve),callback:function ($$v) {_vm.$set(row, "sdeptReserve", $$v)},expression:"row.sdeptReserve"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}],null,false,917998904)}),_c('el-table-column',{attrs:{"label":"个人分配比例","prop":"sindividualReserve"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('TextInput',{attrs:{"config":{
                    type: 'input',
                    width: '100%',
                    modelTextKey: 'sindividualReserve',
                    isNum: true,
                  }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index)}},model:{value:(row.sindividualReserve),callback:function ($$v) {_vm.$set(row, "sindividualReserve", $$v)},expression:"row.sindividualReserve"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}],null,false,1764321464)}),_c('el-table-column',{attrs:{"label":"公司保留%","prop":"scompanyReserve"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return _c('div',{},[(row.scompanyReserve < 0)?_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v(_vm._s(row.scompanyReserve)+",保留率小于0！")]):_c('span',[_vm._v(_vm._s(row.scompanyReserve))])])}}],null,false,668640554)})],1)],1):_vm._e()],1),(_vm.dataForm.secondaryAllocation)?_c('div',[_c('TextInput',{attrs:{"config":{
            label: '三次分配',
            type: 'radio',
            option: _vm.dictList.isOrNot,
          },"labelPosition":"left"},model:{value:(_vm.dataForm.cubicDistribution),callback:function ($$v) {_vm.$set(_vm.dataForm, "cubicDistribution", $$v)},expression:"dataForm.cubicDistribution"}}),(_vm.dataForm.cubicDistribution)?_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.distributionTables.table3,"span-method":_vm.objectSpanMethod3,"cell-class-name":_vm.cellClassNme,"row-class-name":_vm.setRowClassName,"header-cell-style":{
              background: '#FFF2F7FD',
            },"border":""}},[_c('el-table-column',{attrs:{"label":"ID","type":"index"},scopedSlots:_vm._u([{key:"default",fn:function({ $index }){return [_vm._v(_vm._s($index / 2 + 1))]}}],null,false,2715549654)}),_c('el-table-column',{attrs:{"label":"部门名称","prop":"deptName"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [($index % 2 === 0)?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v(_vm._s(row.deptName))]),_c('div',{staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"el-icon-arrow-down"})])]):_c('div',{staticClass:"inner-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":row.innerTable || [],"border":"","row-class-name":(srow) => {
                        return _vm.setRowClassName2(srow, $index);
                      }}},[_c('template',{slot:"empty"},[_c('div',{class:[
                          ($index + 1) % 4 === 0 ? 'row-highlight' : '',
                          'team-leder-add',
                        ],on:{"click":function($event){return _vm.teamLederAdd(row, 2)}}},[_vm._v(" 添加团队长 ")])]),_c('el-table-column',{attrs:{"prop":"userId"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TextInput',{attrs:{"config":{
                            type: 'select',
                            option: row.teamLeaderNameList,
                            width: '100%',
                            placeholder: '请选择团队长',
                          }},model:{value:(scope.row.userName),callback:function ($$v) {_vm.$set(scope.row, "userName", $$v)},expression:"scope.row.userName"}})]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"performanceRate"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TextInput',{attrs:{"config":{
                            type: 'input',
                            width: '100%',
                            modelTextKey: 'performanceRate3',
                            placeholder: '团队长绩效费率',
                            isNum: true,
                          }},on:{"returnVal":function($event){return _vm.returnVal($event, row, $index - 1)}},model:{value:(scope.row.performanceRate),callback:function ($$v) {_vm.$set(scope.row, "performanceRate", $$v)},expression:"scope.row.performanceRate"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])])]}}],null,true)}),_c('el-table-column',{attrs:{"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex-center-between",staticStyle:{"padding":"0 12px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px","cursor":"pointer"},attrs:{"src":require("@/assets/images/kpi/delet.png")},on:{"click":function($event){return _vm.teamLederDelet(row, scope.$index, 2, $index)}}}),(!scope.$index)?_c('div',{staticClass:"team-leder-add",on:{"click":function($event){return _vm.teamLederAdd(row, 2)}}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 添加团队长 ")]):_vm._e()])]}}],null,true)})],2)],1)]}}],null,false,543903184)}),_c('el-table-column',{attrs:{"label":"部门保留(%)","prop":"cdeptReserve"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [(row.cdeptReserve < 0)?_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v(_vm._s(row.cdeptReserve)+",保留率小于0！")]):_c('span',[_vm._v(_vm._s(row.cdeptReserve))])]}}],null,false,1153218072)}),_c('el-table-column',{attrs:{"label":"操作","width":"200"}})],1)],1):_vm._e()],1):_vm._e()]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }